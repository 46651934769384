import * as React from "react";
import meetScreenshot from "../../images/assets/meet-screenshot.png";
import logo1 from "../../images/assets/logos/evernote.svg";
import logo2 from "../../images/assets/logos/gumtree.svg";
import logo3 from "../../images/assets/logos/charthop.svg";
import logo4 from "../../images/assets/logos/gitpod.svg";
import logo5 from "../../images/assets/logos/kin.svg";
import logo6 from "../../images/assets/logos/activeprospect-logo.svg";
import logo7 from "../../images/assets/logos/decathlon.svg";
import logo8 from "../../images/assets/logos/lastminute.svg";
import logo9 from "../../images/assets/logos/vox.svg";
import logo10 from "../../images/assets/logos/sff.png";

import AddToSlackButton from "../buttons/AddToSlack";

export default function Hero() {
  return (
    <div className="bg-gray-50 pt-32 overflow-hidden sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          {/* <h2 className="text-xl mt-10 font-semibold tracking-wider text-indigo-600 uppercase">Free for all without limits</h2> */}
          <p className="mt-8 font-extrabold text-gray-900 tracking-tight text-5xl sm:text-5xl lg:text-7xl">
            Send{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-indigo-600">
              /meet
            </span>{" "}
            in Slack to start your Google Meet instantly
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Type /meet in any Slack channel, group or direct message to generate a new Google Meet.
            Opens with the correct Google Account & automatically updates your Slack status.
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl font-semibold text-indigo-900">
            Trusted by thousands of teams worldwide.
          </p>
          <div>
            {/* 6 logos next to each other here */}
            <div className="my-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo1} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo2} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo3} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo4} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo5} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo6} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo7} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo8} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo9} alt="Slack" />
                </div>
                <div className="flex justify-center px-4 py-4">
                  <img className="h-12" src={logo10} alt="Slack" />
                </div>
              </div>
            </div>
          </div>
          {/* <p className="mt-5 max-w-prose mx-auto text-xl font-semibold text-indigo-900">
            Your ultimate Slack - Google Meet integration. Secure{" "}
            <span className="underline">by default</span>.
          </p> */}
          <div className="mt-5 max-w-max mx-auto">
            <AddToSlackButton />
          </div>
        </div>

        <div className="mt-12">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src={meetScreenshot}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
